import axios from '../axiosConfig';
import Cookies from 'js-cookie';
import { ApiResponse } from '../interfaces';

export interface LoginRequest {
    emailOrUserName: string;
    password: string;
}

interface LoginData {
  token: string;
  username: string;
  userId: string;
}

export interface LoginResponse extends ApiResponse {
  data: LoginData
}

export const loginApi = async (credentials: LoginRequest): Promise<LoginResponse> => {
  const response = await axios.post('/api/Auth/login', credentials);
  return response.data;
};

export const logout = () => {
  Cookies.remove('token'); // Usuń token
  Cookies.remove('username'); // Usuń inne dane, jeśli trzeba
  Cookies.remove('userId');
  window.location.href = '/login'; // Przekierowanie na stronę logowania
};
