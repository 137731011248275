import * as React from "react";

const PasswordIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="24"
    fill="none"
    viewBox="0 0 18 24"
  >
    <path
      fill="#726C6C"
      d="M9 18.024a2.19 2.19 0 0 1-2.19-2.19c0-1.216.974-2.191 2.19-2.191a2.19 2.19 0 1 1 0 4.38m6.571 3.285V10.358H2.43V21.31zm0-13.142a2.19 2.19 0 0 1 2.19 2.19V21.31a2.19 2.19 0 0 1-2.19 2.19H2.43a2.19 2.19 0 0 1-2.191-2.19V10.357c0-1.216.975-2.19 2.19-2.19h1.096v-2.19a5.476 5.476 0 0 1 10.952 0v2.19zM9 2.69a3.286 3.286 0 0 0-3.286 3.286v2.19h6.572v-2.19A3.286 3.286 0 0 0 9 2.69"
    ></path>
  </svg>
);

export default PasswordIcon;
