import EmailIcon from '@/assets/EmailIcon';
import ConfirmPasswordIcon from '@/assets/ConfirmPassowordIcon';
import PasswordIcon from '@/assets/PasswordIcon';
import CustomInput from '@/components/CustomInput';
import SubmitButton from './BlueButton';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { loginApi, LoginRequest } from '@/utils/auth/loginApi';
import Cookies from 'js-cookie';
import axios from 'axios';

// const handleLogin = () => {
//   return useMutation((credentials: LoginRequest) => loginApi(credentials), {
//     onSuccess: (data: any) => {
//       // Zapisz token i nazwę użytkownika w ciasteczkach
//       Cookies.set('token', data.token, {
//         expires: 7, // ciasteczko ważne przez 7 dni
//         secure: true, // tylko HTTPS
//         sameSite: 'strict', // ochrona przed CSRF
//       });
//       Cookies.set('username', data.username, {
//         expires: 7, // ciasteczko ważne przez 7 dni
//         secure: true,
//         sameSite: 'strict',
//       });
//     },
//     onError: (error: any) => {
//       console.error('Logowanie nie powiodło się:', error);
//     },
//   });
// };


const LoginForm: React.FC = () => {

    const router = useRouter();

    const [password, setPassword] = useState("");
    const [emailOrUserName, setEmail] = useState("");
    
    const handleEmailChange = (e: string | number) => {
        setEmail(e.toString());
    }

    const handlePasswordChange = (e: string | number) => {
        setPassword(e.toString());
    }

    const handleForgotPassword = () => {
        router.push("/forgot-password");
    }

    const handleRegister = () => {
        router.push("/register");
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        try
        {
          const result = await loginApi({emailOrUserName, password});
          
          //const result = await axios.post("http://localhost:5012/api/Auth/login", {emailOrUserName, password});
          //console.log(result);
             Cookies.set('token', result.data.token, {
                    expires: 1, // ciasteczko ważne przez 7 dni
                    secure: false, // tylko HTTPS
                    sameSite: 'strict', // ochrona przed CSRF
                  });
            Cookies.set('userId', result.data.userId, {
            expires: 1, // ciasteczko ważne przez 7 dni
            secure: false, // tylko HTTPS
            sameSite: 'strict', // ochrona przed CSRF
            });
                  // Cookies.set('username', result.data.username, {
                  //   expires: 7, // ciasteczko ważne przez 7 dni
                  //   secure: true,
                  //   sameSite: 'strict',
                  // });
          router.push("/home").then(() => {
            window.location.reload();
          });
        }
        catch (err)
        {
           
        }
        
      };

    return (
        <form className='flex flex-col justify-center items-center gap-4' onSubmit={handleSubmit}>
            <div className='lg:w-1/2 2xl:w-1/3 w-4/5'>
                <CustomInput 
                type='email'
                placeholder='Email Address' 
                Icon={EmailIcon}
                value={emailOrUserName}
                onChange={handleEmailChange}
                />
            </div>
            <div className='lg:w-1/2 2xl:w-1/3 w-4/5'>
                <CustomInput 
                type='password' 
                placeholder='Password' 
                Icon={PasswordIcon} 
                isPasswordToggle={true}
                value={password}
                onChange={handlePasswordChange}
                />
            </div>
            <div className='lg:w-1/2 2xl:w-1/3 w-4/5'>
                <p className='text-[#2070e0] cursor-pointer font-semibold mt-0' onClick={handleForgotPassword}>Forgot password?</p>
            </div>
            <div className='lg:w-1/2 2xl:w-1/3 w-4/5'>
                <SubmitButton label='Login' />
                <p className='text-center'>Don’t have an account? <span className='text-[#2070e0] cursor-pointer font-bold' onClick={handleRegister}>Sign up</span></p>
            </div>
        </form>
    )
}

export default LoginForm;