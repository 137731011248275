import GoogleIcon from '@/assets/GoogleIcon';
import LoginForm from '@/components/LoginForm';
import { getCookie } from '@/utils/helpFunctions';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function Home() {
  const router = useRouter();

  useEffect(() => {
    if (typeof document !== 'undefined') {
      if (getCookie('token') != null) {
        router.push('/home');
      }
    }
  }, [router]);

  const handleGoogleLogin = () => {
    window.location.href = 'https://api.business-communicator.com/api/Auth/google-login?returnUrl=/google-auth-success';
  };

  return (
    <>
      <Head>
        <title>Login</title>
        <meta
          name="description"
          content="A ready-to-use starter template for building fast and modern web applications."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="py-24">
        <h1 className="text-center mt-0">Login</h1>

        <LoginForm/>

        <div className="flex justify-center items-center">
          <hr className="md:w-[200px] w-[100px] m-0" />
          <span className="mx-6">or</span>
          <hr className="md:w-[200px] w-[100px] m-0" />
        </div>

        <div className="flex justify-center items-center">
          <button
            className="bg-transparent px-6 py-2 lg:w-1/4 text-[#4988CE] font-[500] text-[18px] 
                       border border-[#4988CE] rounded-[8px] cursor-pointer flex items-center justify-center mt-4 
                       hover:bg-blue-400 hover:text-white transition duration-300 ease-in-out"
            onClick={handleGoogleLogin}
          >
            <div>
              <GoogleIcon />
            </div>
            <span className="ml-2">
              Sign up with Google
            </span>
          </button>
        </div>
      </div>
    </>
  );
}
