import * as React from "react";

const EmailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="20"
    fill="none"
    viewBox="0 0 24 20"
  >
    <path
      fill="#726C6C"
      d="M23.5 3.1c0-1.265-1.035-2.3-2.3-2.3H2.8A2.307 2.307 0 0 0 .5 3.1v13.8c0 1.265 1.035 2.3 2.3 2.3h18.4c1.265 0 2.3-1.035 2.3-2.3zm-2.3 0L12 8.85 2.8 3.1zm0 13.8H2.8V5.4l9.2 5.75 9.2-5.75z"
    ></path>
  </svg>
);

export default EmailIcon;
