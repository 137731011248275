import * as React from "react";

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="39"
    fill="none"
    viewBox="0 0 38 39"
  >
    <g clipPath="url(#clip0_5864_19482)">
      <path
        fill="#fff"
        d="M13.238 1.75a18.786 18.786 0 0 0 1.285 35.892c3.147.812 6.443.847 9.607.104a16.96 16.96 0 0 0 7.689-3.996 17.05 17.05 0 0 0 4.75-7.796 22.06 22.06 0 0 0 .478-9.97h-17.67v7.33H29.61a8.82 8.82 0 0 1-3.758 5.796 10.75 10.75 0 0 1-4.136 1.633 12.3 12.3 0 0 1-4.483 0 11 11 0 0 1-4.17-1.82 11.67 11.67 0 0 1-4.305-5.766 11.37 11.37 0 0 1 0-7.312 11.65 11.65 0 0 1 2.725-4.426 11.03 11.03 0 0 1 11.118-2.903 10.2 10.2 0 0 1 4.05 2.374 1130 1130 0 0 0 3.455-3.452c.594-.62 1.24-1.211 1.826-1.847a18.2 18.2 0 0 0-6.045-3.728 19 19 0 0 0-12.65-.113"
      ></path>
      <path
        fill="#E33629"
        d="M13.238 1.75a19 19 0 0 1 12.65.11 18.2 18.2 0 0 1 6.04 3.746c-.593.636-1.22 1.23-1.825 1.847a1871 1871 0 0 1-3.453 3.438 10.2 10.2 0 0 0-4.049-2.375 11.04 11.04 0 0 0-11.12 2.891 11.65 11.65 0 0 0-2.726 4.427L2.6 11.069a18.86 18.86 0 0 1 10.637-9.32"
      ></path>
      <path
        fill="#F8BD00"
        d="M.968 15.79c.33-1.64.88-3.228 1.633-4.721l6.154 4.777a11.37 11.37 0 0 0 0 7.312Q5.68 25.532 2.6 27.93A18.8 18.8 0 0 1 .968 15.79"
      ></path>
      <path
        fill="#587DBD"
        d="M19.377 15.982h17.67c.605 3.316.441 6.726-.478 9.969a17.05 17.05 0 0 1-4.75 7.796c-1.986-1.55-3.98-3.088-5.967-4.637a8.82 8.82 0 0 0 3.759-5.801H19.377c-.003-2.44 0-4.884 0-7.327"
      ></path>
      <path
        fill="#319F43"
        d="M2.598 27.931q3.078-2.374 6.154-4.774a11.67 11.67 0 0 0 4.31 5.766c1.253.885 2.677 1.5 4.18 1.805a12.3 12.3 0 0 0 4.483 0 10.75 10.75 0 0 0 4.136-1.633c1.986 1.55 3.98 3.087 5.967 4.637a16.96 16.96 0 0 1-7.69 4 20.1 20.1 0 0 1-9.606-.105 18.7 18.7 0 0 1-6.828-3.44 18.9 18.9 0 0 1-5.106-6.256"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_5864_19482">
        <path fill="#fff" d="M0 .5h38v38H0z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default SvgIcon;
