import React, { useState } from "react";
import ShowPasswordIcon from "@/assets/ShowPasswordIcon";

interface InputFieldProps {
  label?: string;
  type?: "text" | "email" | "password" | "number" | "textarea";
  Icon?: React.FC;
  placeholder?: string;
  value?: string | number;
  onChange?: (value: string | number) => void;
  isPasswordToggle?: boolean;
  disabled?: boolean
}

const CustomInput: React.FC<InputFieldProps> = ({
  label,
  type = "text",
  Icon,
  placeholder = "",
  value = "",
  onChange,
  isPasswordToggle = false,
  disabled
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <div className="flex flex-col w-full mb-4">
      {label && <label className="text-sm text-gray-700 mb-1">{label}</label>}
      <div className="relative flex items-center">
        {Icon && <div className="absolute left-3 top-1 text-gray-500">{<Icon />}</div>}

        {type === "textarea" ? (
          <textarea
            disabled={disabled}
            placeholder={placeholder}
            value={value as string}
            onChange={handleChange}
            className="w-full py-2 pl-10 pr-3 border rounded-lg text-gray-800 outline-none focus:ring-2 focus:ring-gray-400"
          />
        ) : (
          <input
            disabled={disabled}
            type={isPasswordToggle && showPassword ? "text" : type}
            placeholder={placeholder}
            value={value as string | number}
            onChange={handleChange}
            className={`w-full py-2 ${Icon ? "pl-10" : "pl-3"} pr-12 border rounded-lg text-gray-800 outline-none focus:ring-2 focus:ring-gray-400`}
          />
        )}

        {type === "password" && isPasswordToggle && (
          <button
            type="button"
            onClick={togglePassword}
            className="absolute right-1 top-2 bg-transparent border-0 focus:outline-none"
          >
            {showPassword ? <ShowPasswordIcon/> : <ShowPasswordIcon/>}
          </button>
        )}
      </div>
    </div>
  );
};

export default CustomInput;
