import * as React from "react";

const ShowPasswordIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="16"
    fill="none"
    viewBox="0 0 24 16"
  >
    <path
      fill="#726C6C"
      d="M12-.007C3.27-.007.645 7.562.621 7.64L.5 8l.12.361c.025.077 2.65 7.646 11.38 7.646S23.355 8.438 23.379 8.36L23.5 8l-.12-.361C23.355 7.562 20.73-.007 12-.007m0 12.582A4.58 4.58 0 0 1 7.425 8 4.58 4.58 0 0 1 12 3.425 4.58 4.58 0 0 1 16.575 8 4.58 4.58 0 0 1 12 12.575"
    ></path>
    <path
      fill="#726C6C"
      d="M12 5.712C10.76 5.712 9.712 6.76 9.712 8S10.76 10.288 12 10.288 14.287 9.24 14.287 8 13.24 5.712 12 5.712"
    ></path>
  </svg>
);

export default ShowPasswordIcon;
